import { Dispatch, useContext } from 'react';
import { FunnelState, FunnelStep, PaymentMean } from '../types';
import { FunnelAction } from './actions';
import { funnelContext } from './context';

const useFunnelContext = (): FunnelState => useContext(funnelContext).state;
export const useFunnelDispatch = (): Dispatch<FunnelAction> =>
  useContext(funnelContext).dispatch;
export const useFunnelCurrentStep = (): FunnelStep =>
  useFunnelContext().currentStep;
export const useFunnelPreviousPage = (): FunnelState['previousPage'] =>
  useFunnelContext().previousPage;
export const useFunnelHistory = (): FunnelStep[] | undefined =>
  useFunnelContext()?.funnelHistory;
export const useFunnelPaymentMean = (): PaymentMean =>
  useFunnelContext().paymentMean;
export const useFunnelLoading = (): FunnelState['isLoading'] =>
  useFunnelContext().isLoading;
export const useFunnelErrorTemplate = (): FunnelState['errorTemplate'] =>
  useFunnelContext().errorTemplate;
export const useFunnelOpen = (): FunnelState['isOpen'] =>
  useFunnelContext().isOpen;
