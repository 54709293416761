import classNames from 'classnames';
import { Children, cloneElement, isValidElement } from 'react';
import { useDiveContext } from '../../../../context';
import { List } from '../List';
import { ListProps } from '../List.types';
import { listGroupHeaderCVA, listGroupItemsCVA } from './ListGroup.cva';
import { ListGroupProps } from './ListGroup.types';

/**
 * ListGroup is used to wrap multiple List elements together
 *
 * @example
 * <ListGroup header="Header" description"Description">
 *  <ListGroup.Item title="Title 1" />
 *  <ListGroup.Item title="Title 2" />
 * </ListGroup>
 */
export function ListGroup({
  header,
  headerAs: Header = 'h2',
  description,
  children,
  className,
  'data-testid': dataTestId,
}: ListGroupProps): JSX.Element {
  const { isTv, device } = useDiveContext();

  const validElementsToBeDisplayed = Children.toArray(children).filter(
    isValidElement<ListProps>
  );

  return (
    <div
      data-testid={dataTestId}
      className={classNames('flex flex-col', className)}
    >
      {(!!header || !!description) && (
        <div className={classNames('flex flex-col', isTv ? 'mb-24' : 'mb-8')}>
          {header && (
            <Header className={listGroupHeaderCVA({ device })}>{header}</Header>
          )}
          {description && isTv && (
            <span
              className={classNames(
                'text-dt-theme-tv-text-list-list-group-subtitle font-hind text-30',
                {
                  'mt-dt-spacing-200': isTv,
                }
              )}
            >
              {description}
            </span>
          )}
        </div>
      )}
      <ul className="list-none m-0 p-0">
        {validElementsToBeDisplayed.map((child, index) => {
          return (
            <li key={child.key || child.props.title} className="block w-full">
              {cloneElement(child, {
                className: classNames(
                  child.props.className,
                  listGroupItemsCVA({
                    device,
                    first: !index,
                    last: index === validElementsToBeDisplayed.length - 1,
                  })
                ),
              })}
            </li>
          );
        })}
      </ul>
      {description && !isTv && (
        <span className="pt-8 text-dt-theme-text-list-row-list-description font-hind text-12">
          {description}
        </span>
      )}
    </div>
  );
}

ListGroup.Item = List;
