import { cva } from 'class-variance-authority';

export const radioWrapperCVA = cva(
  'flex items-center w-auto h-auto font-dt-font-family-system',
  {
    variants: {
      disabled: {
        true: 'opacity-dt-opacity-50',
        false: undefined,
      },
    },
  }
);

export const radioInputCVA = cva(
  'flex appearance-none size-full m-dt-spacing-none self-end',
  {
    variants: {
      disabled: {
        true: 'cursor-not-allowed',
        false: 'cursor-pointer',
      },
    },
  }
);

export const radioCheckIndicatorCVA = cva(
  'pointer-events-none absolute size-full top-0 left-0',
  {
    variants: {
      disabled: {
        true: 'cursor-not-allowed',
        false: 'cursor-pointer',
      },
      isError: {
        true: undefined,
        false: undefined,
      },
      checked: {
        true: 'fill-dt-theme-background-radio-button-radio-button',
        false: 'text-dt-theme-border-radio-button-radio-button',
      },
    },
    compoundVariants: [
      {
        checked: false,
        isError: true,
        className: 'text-dt-theme-border-radio-button-radio-button-error',
      },
    ],
  }
);

export const radioLabelWrapperCVA = cva(
  'flex self-end items-start dt-breakpoint-xs:items-center w-full',
  {
    variants: {
      variant: {
        vertical: 'flex-col dt-breakpoint-xs:flex-row',
        horizontal: 'flex-row',
      },
    },
  }
);

export const radioLablelContentCVA = cva(
  'flex select-none text-dt-font-size-16 leading-dt-font-line-height-20 font-dt-font-weight-regular',
  {
    variants: {
      variant: {
        vertical: 'flex-col',
        horizontal: 'flex-row',
      },
      disabled: {
        true: 'cursor-not-allowed',
        false: 'cursor-pointer',
      },
    },
  }
);

export const radioSubLabelCVA = cva(
  'text-dt-theme-text-radio-button-radio-sublabel',
  {
    variants: {
      variant: {
        vertical: 'mt-dt-spacing-50',
        horizontal: undefined,
      },
    },
  }
);

export const radioActionableElementCVA = cva('', {
  variants: {
    variant: {
      vertical: [
        'ml-dt-spacing-none dt-breakpoint-xs:ml-auto ',
        'mt-dt-spacing-200 dt-breakpoint-xs:mt-dt-spacing-none dt-breakpoint-xs:pl-dt-spacing-200',
      ],
      horizontal: 'ml-auto',
    },
  },
});
