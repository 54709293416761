export const SOURCE_TURBO = 'turbo';

export enum FunnelHodorStep {
  /** Hodor contextual offer page */
  ContextualOffer = 'contextualOfferPage',
  /** Hodor PaymentMeans */
  PaymentMeans = 'paymentMeans',
  /** Hodor template paymentError with alertBox */
  PaymentError = 'paymentError',
}

export enum FunnelInnerStep {
  /** last step payment to finalize payment */
  EndPayment = 'endPayment',
  /** safety code purchase screen */
  PurchaseCode = 'purchaseCode',
  /** voucher entry step for tv */
  Voucher = 'voucher',
  /** deported screen with QR code for tv */
  DeportedPayment = 'deportedPayment',
  /** textBrut template with terms of sale */
  TermsOfSale = 'termsOfSale',
  /** delete registered CB step for tv */
  DeleteCB = 'deleteCB',
}

export enum HapiStatus {
  Complete = 'complete',
  Cancel = 'cancel',
  Pending = 'pending',
  Incomplete = 'incomplete',
}
