import { AlertStatus, Checkbox, List, ListGroup } from '@canalplus/dive';
import classNames from 'classnames';
import {
  ChangeEvent,
  Dispatch,
  MouseEvent,
  SetStateAction,
  useCallback,
  useEffect,
} from 'react';
import { useSelector } from 'react-redux';
import Alert from '../../../../../components/Alert/Alert';
import I18n from '../../../../../lang';
import { displayTVModeSelector } from '../../../../../store/slices/displayMode-selectors';
import styles from './LegalInformation.css';
import { useLegalItems } from './hooks/useLegalItems';

type LegalInformationProps = {
  /**
   * Whether the form containing the checkbox has been validated or not
   */
  isFormValidated: boolean;
  /**
   * Function to be called when all the checkbox were checked
   */
  setIsAllItemsChecked: (isAllItemsChecked: boolean) => void;
  /**
   * Legal information items state to check
   */
  checkedItems: Record<string, boolean>;
  /**
   * Function to set legal information items state
   */
  setCheckedItems: Dispatch<SetStateAction<Record<string, boolean>>>;
};

/**
 * Legal information component capable of displaying a list of checkboxes (web or tv).
 * @returns JSX.Element
 */
export function LegalInformation({
  isFormValidated,
  setIsAllItemsChecked,
  checkedItems,
  setCheckedItems,
}: LegalInformationProps): JSX.Element {
  const isTvDevice = useSelector(displayTVModeSelector);
  const { t } = I18n.useTranslation();

  const yesLabel = t('Common.yes');
  const noLabel = t('Common.no');

  const legalItems = useLegalItems();

  const handleChange = useCallback(
    (
      id: string,
      event?: ChangeEvent<HTMLInputElement> | MouseEvent<HTMLButtonElement>
    ): void => {
      const itemValue = isTvDevice
        ? !checkedItems[id]
        : (event as ChangeEvent<HTMLInputElement>).target.checked;
      const newCheckedItems = { ...checkedItems, [id]: itemValue };
      // Check if all checkboxes are checked
      const checkableItems = legalItems.filter(
        (item) => item.type === 'checkable'
      );
      if (Object.keys(newCheckedItems).length === checkableItems?.length) {
        const isAllItemsChecked = Object.values(newCheckedItems).every(
          (item) => item
        );
        setIsAllItemsChecked(isAllItemsChecked);
      }
      // Update checked items
      setCheckedItems(newCheckedItems);
    },
    [
      isTvDevice,
      legalItems,
      checkedItems,
      setIsAllItemsChecked,
      setCheckedItems,
    ]
  );

  useEffect(() => {
    const checkableItems = legalItems.filter(
      (item) => item.type === 'checkable'
    );

    if (Object.keys(checkedItems).length === checkableItems?.length) {
      const isAllItemsChecked = Object.values(checkedItems).every(
        (item) => item
      );
      setIsAllItemsChecked(isAllItemsChecked);
    }
  }, [checkedItems, legalItems, setIsAllItemsChecked]);

  if (isTvDevice) {
    return (
      <div className={styles.legalInformation}>
        <ListGroup>
          {legalItems.map(
            (item) =>
              item.type === 'checkable' && (
                <div key={item.name}>
                  <List
                    id={item.name}
                    title={item.label as string}
                    label={checkedItems[item.name] ? yesLabel : noLabel}
                    onClick={(event) => handleChange(item.name, event)}
                  />
                  {isFormValidated && !checkedItems[item.name] && (
                    <Alert
                      status={AlertStatus.Error}
                      message={item.alertLabel}
                    />
                  )}
                </div>
              )
          )}
        </ListGroup>
      </div>
    );
  }

  return (
    <div className={styles.legalInformation}>
      {legalItems.map((item) => {
        const isReadonly = item.type === 'readonly';
        return (
          <div
            key={item.name}
            className={classNames(styles.legalInformation__item, {
              [styles['LegalInformation__item--readonly']]: isReadonly,
            })}
          >
            {isReadonly ? (
              item.label
            ) : (
              <>
                <Checkbox
                  id={item.name}
                  label={item.label}
                  name={item.name}
                  value={item.name}
                  checked={checkedItems[item.name]}
                  isFormValidated={isFormValidated}
                  onChange={(event) => handleChange(item.name, event)}
                  required
                />
                {isFormValidated && !checkedItems[item.name] && (
                  <Alert
                    className={styles.legalInformation__alertBox}
                    status={AlertStatus.Error}
                    message={item.alertLabel}
                  />
                )}
              </>
            )}
          </div>
        );
      })}
    </div>
  );
}
