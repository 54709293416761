import {
  addImmersiveNoScroll,
  removeImmersiveNoScroll,
} from '@canalplus/mycanal-commons';
import { useEffect } from 'react';

type UseImmersiveNoScrollType = {
  prefix?: string;
};

/**
 * Add a noscroll class to the immersive to prevent scrolling. This is useful when a modal is open on immsersive.
 * The same class is removed when the component unmounts.
 */
export const useImmersiveNoScroll = ({
  prefix,
}: UseImmersiveNoScrollType = {}): void => {
  useEffect(() => {
    addImmersiveNoScroll(prefix);
    return () => {
      removeImmersiveNoScroll(prefix);
    };
  }, [prefix]);
};
