import { createContext, useMemo, useReducer } from 'react';
import { FunnelState, FunnelStep } from '../types';
import { FunnelAction } from './actions';
import funnelReducer from './reducer';

const INITIAL_STATE: FunnelState = {
  currentStep: {},
  isLoading: true,
  isOpen: true,
  paymentMean: {},
  previousPage: undefined,
  errorTemplate: undefined,
};

type IFunnelContext = {
  state: FunnelState;
  dispatch: React.Dispatch<FunnelAction>;
};

const funnelContext = createContext({} as IFunnelContext);
const { Provider } = funnelContext;

function FunnelProvider({
  children,
  initialStep,
}: {
  children: React.ReactNode;
  initialStep: FunnelStep;
}): JSX.Element {
  const [state, dispatch] = useReducer(funnelReducer, {
    ...INITIAL_STATE,
    currentStep: initialStep,
    funnelHistory: [initialStep],
  });

  const value = useMemo(
    () => ({
      state,
      dispatch,
    }),
    [state]
  );
  return <Provider value={value}>{children}</Provider>;
}

export { FunnelProvider, funnelContext };
