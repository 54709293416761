import { Button } from '@canalplus/dive';
import {
  IVirtualKeyboardContext,
  TvodTitle,
} from '@canalplus/mycanal-sharedcomponent';
import { Binder } from '@canalplus/one-navigation';
import { useContext, useEffect } from 'react';
import {
  VirtualKeyboard,
  virtualKeyboardContext,
} from '../../../../components/VirtualKeyboard/VirtualKeyboard';
import { MIDDLEWARE_FUNNEL_PROMOTIONAL } from '../../../../helpers/oneNavigation/middleware';
import I18n from '../../../../lang';
import { VoucherInput } from './VoucherInput';
import styles from './VoucherStep.css';

type VoucherStepProps = {
  entryBar: any; // TODO: type
  onSubmit?: (voucherKey: string) => void;
  onFocusable?: () => void;
};
export function VoucherStep({
  entryBar,
  onSubmit,
  onFocusable,
}: VoucherStepProps): JSX.Element {
  const { t } = I18n.useTranslation();
  const { deportedScreenTitle, deportedScreenSubtitle, placeHolderLabel } =
    entryBar || {};

  const {
    state: { inputValue },
  } = useContext<IVirtualKeyboardContext>(virtualKeyboardContext);

  /**
   * Input value from **VirtualKeyboard** is a `string[]`\
   * We are transforming it into a simple string
   */
  const joinedInputValue = inputValue.join('').toUpperCase();

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (joinedInputValue) {
      onSubmit?.(joinedInputValue);
    }
  };

  useEffect(() => {
    if (onFocusable) {
      onFocusable();
    }
  }, [onFocusable]);

  return (
    <Binder
      middleware={MIDDLEWARE_FUNNEL_PROMOTIONAL}
      className={styles.voucherStep}
    >
      {/* Update component size subtitle because voucher step subtitle is specific */}
      <TvodTitle
        title={deportedScreenTitle}
        subtitle={deportedScreenSubtitle}
      />
      <form>
        <VoucherInput
          placeHolderLabel={placeHolderLabel}
          value={joinedInputValue}
        />
        <Button width="fixed" type="submit" onClick={handleSubmit}>
          {t('FunnelTvod.validate')}
        </Button>
      </form>
      <div className={styles.voucherStep__virtualKeyboardWrapper}>
        <VirtualKeyboard />
      </div>
    </Binder>
  );
}
