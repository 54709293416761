import { Button } from '@canalplus/dive';
import { Binder } from '@canalplus/one-navigation';
import { TurboPlatform } from '@canalplus/sdk-core';
import { ApiV2PaymentMeans } from '@dce-front/hodor-types';
import { ApiV2TvodPaymentState } from '@dce-front/hodor-types/api/v2/tvod/payment/definitions';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { PaymentMeanCode } from '../../../../constants/tvod';
import { isTim } from '../../../../helpers/application/application-helper';
import { MIDDLEWARE_FUNNEL_VOD } from '../../../../helpers/oneNavigation/middleware';
import I18n from '../../../../lang';
import { FromProp } from '../../../../server/modules/fetchWithQuery/types';
import { displayTVModeSelector } from '../../../../store/slices/displayMode-selectors';
import { FunnelInnerStep } from '../../../../templates/FunnelTvod/stores/constants';
import {
  addPaymentMean,
  decrementFunnelHistory,
  incrementFunnelHistory,
  setCurrentStep,
} from '../../../../templates/FunnelTvod/stores/funnel/actions';
import {
  useFunnelCurrentStep,
  useFunnelDispatch,
  useFunnelPaymentMean,
} from '../../../../templates/FunnelTvod/stores/funnel/hooks';
import { TVOD_ERROR } from '../../helpers/const';
import { DeportedPayment } from '../DeportedPayment/DeportedPayment';
import { PurchaseCode } from '../PurchaseCode/PurchaseCode';
import { TvodForm } from '../TvodForm/TvodForm';
import { CrmInformation } from './CrmInformation/CrmInformation';
import { DeleteCBModal } from './DeleteCBModal/DeleteCBModal';
import { LegalInformation } from './LegalInformation/LegalInformation';
import { useHandleFunnelModalClose } from './hooks/useHandleFunnelModalClose/useHandleFunnelModalClose';
import { useHandleSubmitPayment } from './hooks/useHandleSubmitPayment/useHandleSubmitPayment';
import { usePaymentMeanTitle } from './hooks/usePaymentMeanTitle/usePaymentMeanTitle';
import { useSortedAvailablePaymentMeans } from './hooks/useSortedAvailablePaymentMeans/useSortedAvailablePaymentMeans';

type PaymentMeansProps = {
  data?: ApiV2PaymentMeans;
  onFocusable?: () => void;
} & FromProp;

export function PaymentMeans({
  from,
  data,
  onFocusable,
}: PaymentMeansProps): JSX.Element | null {
  const { t } = I18n.useTranslation();
  const isTvDevice = useSelector(displayTVModeSelector);
  const currentStep = useFunnelCurrentStep();
  const selectedPaymentMean = useFunnelPaymentMean();
  const funnelDispatch = useFunnelDispatch();

  const { crmInformation, detail, purchase, states, tracking } = data || {};
  const { source, crmResponseProxy } = crmInformation || {};
  const turboMedia = crmInformation?.media as TurboPlatform;
  const { promotion, promotionalPrice } = detail?.prices || {};

  const sortedAvailablePaymentMeans =
    useSortedAvailablePaymentMeans(crmInformation);
  const title = usePaymentMeanTitle(detail);
  // Check items LegalInformations
  const [checkedItems, setCheckedItems] = useState({});
  const [showDeleteCBModal, setShowDeleteCBModal] = useState(false);

  const errorCallbackState = states?.find(
    (content: ApiV2TvodPaymentState) => content.id === TVOD_ERROR
  )?.callback;

  // Add CB1SHOT to the list of payment means (as last item)
  const paymentMeansWithCB1Shot = useMemo(() => {
    return !isTim() && sortedAvailablePaymentMeans
      ? [
          ...sortedAvailablePaymentMeans,
          {
            paymentMeanCode: PaymentMeanCode.CB1SHOT,
            paymentMeanLabel: t('FunnelTvod.libCB1Shot'),
          },
        ]
      : sortedAvailablePaymentMeans;
  }, [sortedAvailablePaymentMeans, t]);

  // Payment methods with only the registered credit card
  const paymentMeanCBR =
    paymentMeansWithCB1Shot &&
    paymentMeansWithCB1Shot.find(
      (paymentMean) => paymentMean.paymentMeanCode === PaymentMeanCode.TVOD_CB
    );

  // Inner steps
  const isChoosePaymentStep = !currentStep.innerStep;
  const isEndPaymentStep = currentStep.innerStep === FunnelInnerStep.EndPayment;
  const isDeportedPaymentStep =
    currentStep.innerStep === FunnelInnerStep.DeportedPayment;
  const isPurchaseCodeStep =
    currentStep.innerStep === FunnelInnerStep.PurchaseCode;
  const isDeleteCBStep =
    currentStep.innerStep === FunnelInnerStep.DeleteCB && isTvDevice;

  // Conditions
  const isFree = promotion && promotionalPrice === 0;
  const isSinglePaymentMean = paymentMeansWithCB1Shot?.length === 1;
  const hasPaymentMeans = paymentMeansWithCB1Shot?.length !== 0;
  const displayPurchaseButton =
    (hasPaymentMeans && crmResponseProxy?.returnCode) || isFree;
  const shouldSkipPaymentMeans =
    (isTvDevice && isChoosePaymentStep && (isFree || isSinglePaymentMean)) ||
    !displayPurchaseButton; // Should skip to EndPayment step

  // Custom hook - Handle submit payment
  const handleSubmitPayment = useHandleSubmitPayment({
    currentContent: states,
    purchase,
    turboMedia,
    tracking,
    detail,
    isFree,
  });

  // Custom hook - handle close button
  // display when there is no paymentMeansWithCB1Shot and no return code from the CRM(kiss or turbo)
  const handleClose = useHandleFunnelModalClose(errorCallbackState);

  /**
   * Reset Legal Information state on Choose Payment step
   */
  useEffect(() => {
    if (isChoosePaymentStep) {
      setCheckedItems({});
    }
  }, [isChoosePaymentStep]);

  // On mount, we add the first payment mean to the funnel (if none, it should be the CB1Shot)
  useEffect(() => {
    if (paymentMeansWithCB1Shot) {
      funnelDispatch(addPaymentMean(paymentMeansWithCB1Shot[0]));
    }
  }, [paymentMeansWithCB1Shot, funnelDispatch]);

  // On mount and when user come back from paymentPage we need to reload page
  useEffect(() => {
    const handlePageShow = (event: PageTransitionEvent) => {
      // Check if the page was loaded from the cache
      if (event.persisted) {
        window.location.reload();
      }
    };

    window.addEventListener('pageshow', handlePageShow);

    return () => window.removeEventListener('pageshow', handlePageShow);
  }, []);

  /**
   * #### TV ####
   *
   * Scenario 0: FREE (no paymentMeans needed)
   *  - Skip CRM and jump to EndPayment (no CrmStandalone info needed)
   *
   * Scenario 1: No available paymentMeans registered on user's account
   *  - Skip CRM and jump to EndPayment (Show CB1Shot CrmStandalone)
   *
   * Scenario 2: At least one PaymentMean registered (e.g: 1. IBAN)
   *  - Show CrmInformation (choice) (Choose between IBAN and CB1Shot)
   *  - Next, Show EndPayment step (Show chosen payment method as CrmStandalone)
   */
  useEffect(() => {
    // Should jump to EndPayment directly (in case of Free content or single payment mean [CB1Shot])
    if (shouldSkipPaymentMeans && !isEndPaymentStep) {
      // Remove current paymentMeans step from history
      funnelDispatch(decrementFunnelHistory());
      // Increment with new step EndPayment and jump to it
      const initStep = {
        ...currentStep,
        innerStep: FunnelInnerStep.EndPayment,
      };
      funnelDispatch(setCurrentStep(initStep));
      funnelDispatch(incrementFunnelHistory(initStep));
    }
  }, [currentStep, shouldSkipPaymentMeans, isEndPaymentStep, funnelDispatch]);

  const handleClickTOS = useCallback(() => {
    const initStep = { ...currentStep, innerStep: FunnelInnerStep.TermsOfSale };
    funnelDispatch(setCurrentStep(initStep));
    funnelDispatch(incrementFunnelHistory(initStep));
  }, [currentStep, funnelDispatch]);

  /**
   * For focus after deleted card on TV
   */
  useEffect(() => {
    if (onFocusable && data) {
      onFocusable();
    }
  }, [data, onFocusable]);

  if (data && isDeportedPaymentStep) {
    return (
      <DeportedPayment
        currentContent={states}
        purchaseId={data.purchase?.purchaseId}
        tracking={tracking}
      />
    );
  }

  if (isPurchaseCodeStep) {
    return (
      <PurchaseCode
        currentContent={states}
        purchase={purchase}
        turboMedia={turboMedia}
        tracking={tracking}
        detail={detail}
        isFree={isFree}
      />
    );
  }

  // Only displayed when the user has clicked on delete CB button on 'ChoosePaymentStep' (TV step)
  if (isDeleteCBStep && paymentMeanCBR) {
    return (
      <DeleteCBModal
        onClose={() => {
          funnelDispatch(decrementFunnelHistory());
        }}
        paymentMeanCBR={paymentMeanCBR}
        onFocusable={onFocusable}
      />
    );
  }

  return data ? (
    <>
      <TvodForm
        from={from}
        onFocusable={onFocusable}
        title={title}
        data={data}
        onSubmit={handleSubmitPayment}
        onClose={handleClose}
        onClickTOS={handleClickTOS}
        noFooter={(isTvDevice && isChoosePaymentStep) || !displayPurchaseButton}
        renderContent={({ isFormSubmitted, setIsFormValid }) => (
          <>
            {/* Choice of paymentMean and/or Legal (desktop only) (Desktop/TV) */}
            {!isEndPaymentStep && (
              <Binder middleware={MIDDLEWARE_FUNNEL_VOD} forceFocusOnMount>
                {paymentMeansWithCB1Shot?.length && !isFree && (
                  <CrmInformation
                    source={source}
                    paymentMeans={paymentMeansWithCB1Shot}
                    setShowDeleteCBModal={setShowDeleteCBModal}
                  />
                )}
                {/* We don't show LegalInformation on PaymentMean template for TV, only in EndPayment */}
                {!isTvDevice && (
                  <LegalInformation
                    isFormValidated={isFormSubmitted}
                    setIsAllItemsChecked={setIsFormValid}
                    checkedItems={checkedItems}
                    setCheckedItems={setCheckedItems}
                  />
                )}
              </Binder>
            )}

            {/* Finalize paymentMean (TV only) */}
            {isTvDevice && isEndPaymentStep && (
              <Binder middleware={MIDDLEWARE_FUNNEL_VOD} forceFocusOnMount>
                {selectedPaymentMean && !isFree && (
                  <CrmInformation
                    source={source}
                    paymentMeans={[selectedPaymentMean]}
                  />
                )}
                {displayPurchaseButton && (
                  <LegalInformation
                    isFormValidated={isFormSubmitted}
                    setIsAllItemsChecked={setIsFormValid}
                    checkedItems={checkedItems}
                    setCheckedItems={setCheckedItems}
                  />
                )}
              </Binder>
            )}
            {/* Display close button instead of purchase button */}
            {!displayPurchaseButton && (
              <Binder middleware={MIDDLEWARE_FUNNEL_VOD} forceFocusOnMount>
                <Button variant="primary" onClick={handleClose} width="fixed">
                  {t('FunnelTvod.close')}
                </Button>
              </Binder>
            )}
          </>
        )}
      />
      {/* Display DeleteCBModal over the TvodForm on desktop */}
      {!isTvDevice && showDeleteCBModal && paymentMeanCBR && (
        <DeleteCBModal
          onClose={() => setShowDeleteCBModal(false)}
          paymentMeanCBR={paymentMeanCBR}
        />
      )}
    </>
  ) : null;
}
