export enum TvodStep {
  ContextualOffer = 'contextualOffer',
  PaymentMeans = 'paymentMeans',
}

export enum TypePrice {
  Major = 'major',
  Minor = 'minor',
}

export type Labels = {
  titleLabels: {
    secureSubtitle: string;
  };
};
