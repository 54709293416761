import { useEffect } from 'react';
import { useAppHistory } from '../../../../../helpers/hooks/reactRouter';

/**
 * Hook to handle path according to the current funnel hodorStep, without a unnecessary re-render.
 */
export const useHandleFunnelPath = ({ path }: { path?: string }): void => {
  const history = useAppHistory();

  useEffect(() => {
    if (path && path !== history.location.pathname) {
      // We only update the path, without updating the state
      history.replace(path, history.location.state);
    }
  }, [path, history]);
};
