import {
  ParentalRatingCSA10Svg,
  ParentalRatingCSA12Svg,
  ParentalRatingCSA16Svg,
  ParentalRatingCSA18Svg,
  ParentalRatingCSAAllAudienceSvg,
  ParentalRatingIRS12Svg,
  ParentalRatingIRS15Svg,
  ParentalRatingIRS18Svg,
  ParentalRatingIRS6Svg,
  ParentalRatingIRSAllAudienceSvg,
  ParentalRatingKRRIT12Svg,
  ParentalRatingKRRIT16Svg,
  ParentalRatingKRRIT18Svg,
  ParentalRatingKRRIT7Svg,
  ParentalRatingKRRITAllAudienceSvg,
} from '@canalplus/dive';
import { Authority } from '@canalplus/sdk-hodor';
import { ParentalRating as ParentalRatingHodor } from '@dce-front/hodor-types/modules/media_contents/definitions';
import { ComponentType, Fragment } from 'react';
import { AdvisoriesIcons } from './AdvisoriesIcons';

type IconsMapping = {
  [key in Authority]: {
    '1'?: ComponentType;
    '2': ComponentType;
    '3': ComponentType;
    '4': ComponentType;
    '5': ComponentType;
    '6'?: ComponentType;
  };
};

const iconsMapping: IconsMapping = {
  // poland
  KRRIT: {
    '1': ParentalRatingKRRITAllAudienceSvg,
    '2': ParentalRatingKRRIT7Svg,
    '3': ParentalRatingKRRIT12Svg,
    '4': ParentalRatingKRRIT16Svg,
    '5': ParentalRatingKRRIT18Svg,
  },
  // france
  CSA: {
    '1': ParentalRatingCSAAllAudienceSvg,
    '2': ParentalRatingCSA10Svg,
    '3': ParentalRatingCSA12Svg,
    '4': ParentalRatingCSA16Svg,
    '5': ParentalRatingCSA18Svg,
    '6': ParentalRatingCSA18Svg, // Can it be removed?
  },
  // italy
  IRS: {
    '1': ParentalRatingIRSAllAudienceSvg,
    '2': ParentalRatingIRS6Svg,
    '3': ParentalRatingIRS12Svg,
    '4': ParentalRatingIRS15Svg,
    '5': ParentalRatingIRS18Svg,
    '6': ParentalRatingIRS18Svg, // Can it be removed?
  },
};

const ALL_AUDIENCE = '1';

export type CustomParentalRating = ParentalRatingHodor & {
  title?: string;
};

export type ParentalRatingsProps = {
  parentalRatings?: CustomParentalRating[];
  className?: string;
  ignoreCSATousPublics?: boolean;
};

export function ParentalRatings({
  parentalRatings = [],
  className,
  ignoreCSATousPublics = false,
}: ParentalRatingsProps): JSX.Element {
  return (
    <>
      {parentalRatings.map(
        ({ authority = Authority.CSA, value, title, advisories }) => {
          if (!value) return null;
          if (
            ignoreCSATousPublics &&
            authority === Authority.CSA &&
            value === ALL_AUDIENCE
          )
            return null;
          const IconTag = iconsMapping[authority][
            value
          ] as keyof JSX.IntrinsicElements;
          return (
            <Fragment key={value}>
              {IconTag && (
                <IconTag
                  className={className}
                  title={title || `Icon-${authority}-${value}`}
                  key={`${authority}-${value}`}
                />
              )}
              {advisories && (
                <AdvisoriesIcons
                  advisoriesRatings={advisories}
                  className={className}
                />
              )}
            </Fragment>
          );
        }
      )}
    </>
  );
}
