import {
  MoralityDrugsNSvg,
  MoralityProfanityWSvg,
  MoralitySexSSvg,
  MoralityViolencePSvg,
} from '@canalplus/dive';
import { ComponentType } from 'react';
import styles from './AdvisoriesIcons.module.css';

type AdvisoriesIconsMapping = {
  'N'?: ComponentType;
  'P'?: ComponentType;
  'S'?: ComponentType;
  'W'?: ComponentType;
};

const advisoriesMapping: AdvisoriesIconsMapping = {
  // poland
  'N': MoralityDrugsNSvg,
  'P': MoralityViolencePSvg,
  'S': MoralitySexSSvg,
  'W': MoralityProfanityWSvg,
};

export type AdvisoriesIconsProps = {
  advisoriesRatings: string[];
  className?: string;
};

/**
 * Component to display polish advisories icons from a list of advisories ratings.\
 * `N: Drugs, P: Violence, S: Sex, W: Profanity`
 *
 * @param props.advisoriesRatings  The list of advisories ratings
 * @param props.className          The class name to apply to the component
 * @returns Advisories icons SVGs or null if no advisories ratings
 */
export function AdvisoriesIcons({
  advisoriesRatings,
  className,
}: AdvisoriesIconsProps): JSX.Element | null {
  if (!advisoriesRatings?.length) {
    return null;
  }

  return (
    <span className={styles.AdvisoriesIcons__wrapper}>
      {advisoriesRatings.map((advisory) => {
        const AdvisoryIcon = advisoriesMapping[
          advisory
        ] as keyof JSX.IntrinsicElements;

        return !AdvisoryIcon ? null : (
          <AdvisoryIcon
            title={`ParentalRating-advisory-${advisory}`}
            className={className}
            key={`advisory-${advisory}`}
          />
        );
      })}
    </span>
  );
}
