import { ButtonIcon, MaterialChevronLeftSvg } from '@canalplus/dive';
import { ModalV2, ModalV2Size } from '@canalplus/mycanal-sharedcomponent';
import { useImmersiveNoScroll } from '@canalplus/mycanal-util-react';
import {
  KEY_BACK,
  Layer,
  useActiveLayer,
  useKeyCatcher,
  useStore,
} from '@canalplus/one-navigation';
import classNames from 'classnames/bind';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Spinner from '../../../components/Spinner/Spinner';
import { useAppHistory } from '../../../helpers/hooks/reactRouter';
import { LAYER_VOD_FUNNEL } from '../../../helpers/oneNavigation/layers';
import I18n from '../../../lang';
import { FromProp } from '../../../server/modules/fetchWithQuery/types';
import { displayTVModeSelector } from '../../../store/slices/displayMode-selectors';
import { FunnelInnerStep } from '../stores/constants';
import {
  useFunnelCurrentStep,
  useFunnelErrorTemplate,
  useFunnelHistory,
  useFunnelLoading,
  useFunnelOpen,
} from '../stores/funnel/hooks';
import FunnelContent from './FunnelContent';
import styles from './FunnelModal.css';
import { useHandleFunnelModalBack } from './hooks/useHandleFunnelModalBack/useHandleFunnelModalBack';

const cx = classNames.bind(styles);

type FunnelModalProps = {
  setIsOpen?: (openModal: boolean) => void;
  fullPage?: boolean;
} & FromProp;

const SPINNER_SIZE = 5; // 5rem
const SPINNER_SIZE_TV = 5.5; // 5.5rem
const FUNNEL_MODAL_ID = 'modal_funnel_tvod';

export function FunnelModal({
  from,
  setIsOpen,
  fullPage,
}: FunnelModalProps): JSX.Element {
  const { t } = I18n.useTranslation();
  const store = useStore();
  const history = useAppHistory();
  const isTvDevice = useSelector(displayTVModeSelector);

  const funnelHistory = useFunnelHistory();
  const currentStep = useFunnelCurrentStep();
  const isLoading = useFunnelLoading();
  const isOpen = useFunnelOpen();
  const errorTemplate = useFunnelErrorTemplate();

  const isFirstStep = funnelHistory?.length === 1;
  const isTermsOfSale = currentStep.innerStep === FunnelInnerStep.TermsOfSale;

  const modalSize = isTvDevice ? ModalV2Size.FullScreen : undefined;

  const handleCloseModal = useCallback(() => {
    const immersiveCallback =
      history.location.state?.immersive?.mainOnClick?.path?.includes(
        'callbackState'
      ) || false;
    const pageCallback =
      history.location.state?.page?.mainOnClick?.path?.includes(
        'callbackState'
      ) || false;

    // On CSR, we only go back to the detailPage
    if (!fullPage && !immersiveCallback && !pageCallback) {
      history.replace('..', history.location.state);
    }
    // Close funnel modal
    setIsOpen?.(false);
  }, [setIsOpen, history, fullPage]);

  const handleFunnelModalBack = useHandleFunnelModalBack(
    handleCloseModal,
    fullPage
  );

  useEffect(() => {
    if (!isOpen) {
      handleCloseModal();
    }
  }, [isOpen, handleCloseModal]);

  const handleFocusable = useCallback(() => {
    if (isTvDevice) {
      store.focusDefault();
    }
  }, [store, isTvDevice]);

  useActiveLayer(LAYER_VOD_FUNNEL);
  useKeyCatcher(KEY_BACK, handleFunnelModalBack, LAYER_VOD_FUNNEL);
  useImmersiveNoScroll({ prefix: 'immersive' });
  return fullPage ? (
    <div
      id={FUNNEL_MODAL_ID}
      className={cx('funnelModal', { 'funnelModal--fullPage': fullPage })}
    >
      {!errorTemplate && !isTvDevice && (
        <ButtonIcon
          className={cx('funnelModal__backButton')}
          icon={<MaterialChevronLeftSvg />}
          onClick={handleFunnelModalBack}
          aria-label={t('Icon.back')}
        />
      )}
      <Layer layer={LAYER_VOD_FUNNEL}>
        {isLoading && (
          <div
            aria-hidden="true"
            className={cx('funnelModal__spinner__backdrop')}
          >
            <Spinner size={isTvDevice ? SPINNER_SIZE_TV : SPINNER_SIZE} />
          </div>
        )}
        <FunnelContent from={from} onFocusable={handleFocusable} />
      </Layer>
    </div>
  ) : (
    <ModalV2
      id={FUNNEL_MODAL_ID}
      isTvDevice={isTvDevice}
      onBack={
        !isFirstStep && !errorTemplate ? handleFunnelModalBack : undefined
      }
      onClose={handleCloseModal}
      size={modalSize}
      type={isTermsOfSale ? 'textBrut' : 'funnel'}
      className={cx('funnelModal')}
      hidePadding={isTvDevice}
      closeAriaLabel={t('Icon.close')}
      backAriaLabel={t('Icon.back')}
    >
      <Layer layer={LAYER_VOD_FUNNEL}>
        {isLoading && (
          <div
            aria-hidden="true"
            className={cx('funnelModal__spinner__backdrop')}
          >
            <Spinner size={isTvDevice ? SPINNER_SIZE_TV : SPINNER_SIZE} />
          </div>
        )}
        <FunnelContent from={from} onFocusable={handleFocusable} />
      </Layer>
    </ModalV2>
  );
}
