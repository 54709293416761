import { useId } from 'react';
import { StatusCheckSvg } from '../../../icons/icons';
import { LabelArrangementOrientation } from '../SelectionControls.types';
import {
  checkboxActionableElementCVA,
  checkboxCheckIndicatorCVA,
  checkboxInputCVA,
  checkboxLabelContentCVA,
  checkboxLabelWrapper,
  checkboxSubLabelCVA,
  checkboxWrapperCVA,
} from './Checkbox.cva';
import { CheckboxProps } from './Checkbox.types';

/**
 * A Checkbox allows users to select multiple options from a set
 *
 * @example
 *
 * ```tsx
 * <Checkbox
 *    label="Option 1"
 *    name="option"
 *    value="1"
 *    checked
 *    onChange={handleChange}
 *    disabled={false}
 *    isFormValidated={false}
 *    required={false}
 * />
 * ```
 */
export function Checkbox({
  id,
  label,
  subLabel,
  name,
  value,
  isFormValidated,
  checked,
  disabled,
  required,
  variant = LabelArrangementOrientation.Horizontal,
  actionableElement,
  className,
  'data-testid': dataTestId,
  onChange,
}: CheckboxProps): JSX.Element {
  const checkboxUniqueId = useId();
  const inputId = id || checkboxUniqueId;
  const hasError = isFormValidated && required && !checked;

  return (
    <div
      data-testid={dataTestId}
      className={checkboxWrapperCVA({ disabled, className })}
    >
      <div className="relative self-start min-w-22 size-22 box-border mr-dt-spacing-200">
        <input
          type="checkbox"
          id={inputId}
          name={name}
          checked={checked}
          value={value}
          disabled={disabled}
          onChange={onChange}
          required={required}
          aria-checked={checked}
          aria-invalid={hasError ? true : undefined}
          className={checkboxInputCVA({ checked, hasError, disabled })}
        />
        {checked && (
          <StatusCheckSvg className={checkboxCheckIndicatorCVA({ disabled })} />
        )}
      </div>
      <div className={checkboxLabelWrapper({ variant })}>
        <label
          htmlFor={inputId}
          className={checkboxLabelContentCVA({ variant, disabled })}
        >
          {/* Label */}
          {label && (
            <span className="text-dt-theme-text-checkbox-checkbox-label mr-dt-spacing-100">
              {label}
            </span>
          )}

          {/* SubLabel */}
          {subLabel && (
            <span className={checkboxSubLabelCVA({ variant })}>{subLabel}</span>
          )}
        </label>
        {actionableElement && checked && (
          <div className={checkboxActionableElementCVA({ variant })}>
            {actionableElement}
          </div>
        )}
      </div>
    </div>
  );
}
