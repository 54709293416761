import { TurboPlatform } from '@canalplus/sdk-core';
import { ApiV2TvodTracking } from '@dce-front/hodor-types/api/v2/common/dto/tvod/defintions';
import { ApiV2TvodPaymentState } from '@dce-front/hodor-types/api/v2/tvod/payment/definitions';
import {
  ApiV2PaymentMeansDetail,
  ApiV2PaymentMeansPurchase,
} from '@dce-front/hodor-types/api/v2/tvod/payment_means/definitions';
import { useCallback } from 'react';
import { isPaymentMeanCodeCB } from '../../../../../constants/tvod';
import { PurchaseCodeState } from '../../../../../store/slices/purchaseCode';
import { useFunnelPaymentMean } from '../../../../../templates/FunnelTvod/stores/funnel/hooks';
import { useRedirectPayment } from '../../PaymentMeans/hooks/useRedirectPayment/useRedirectPayment';
import { useProcessPayment } from '../useProcessPayment/useProcessPayment';

/**
 * Hook custom to handle payment KISS and Turbo
 */
export const usePayment = ({
  currentContent,
  purchase,
  turboMedia,
  tracking,
  detail,
  isFree,
}: {
  currentContent?: ApiV2TvodPaymentState[];
  purchase?: ApiV2PaymentMeansPurchase;
  turboMedia?: TurboPlatform;
  tracking?: ApiV2TvodTracking;
  detail?: ApiV2PaymentMeansDetail;
  isFree?: boolean;
}): ((validationToken?) => Promise<void>) => {
  const handleProcessPayment = useProcessPayment();

  const selectedPaymentMean = useFunnelPaymentMean();
  const handleRedirectPayment = useRedirectPayment();

  //  PaymentCard logical
  // If paymentMeanCode/paymentMeanType is defined, we consider it's a PaymentCard
  //  - paymentMeanCode => KISS   France
  //  - paymentMeanType => TURBO  Italy
  const isCBCanal =
    (selectedPaymentMean?.paymentMeanCode &&
      isPaymentMeanCodeCB(selectedPaymentMean?.paymentMeanCode)) ||
    false;
  const isCBTim = selectedPaymentMean?.onlinePaymentMode || false;

  return useCallback(
    async (validationToken?) => {
      // Store purchase info to use it on generic tracking tool for confirmation page level only
      const purchaseInfo: PurchaseCodeState['purchaseInfo'] = {
        purchaseId: tracking?.dataLayer?.purchase_id,
        paymentMethod: selectedPaymentMean?.paymentMeanLabel,
        productCategory: tracking?.dataLayer?.product_category,
        productPrice: tracking?.dataLayer?.product_price,
      };
      // Make sure we have all the data we need
      if (!purchase || !detail) {
        return;
      }
      /**
       * Purchase code not activated
       * * CB1Shot & Saved CB1Shot (TVOD_CB) from CheckPaymentPage
       *  - New CB1Shot or a previous saved CB1Shot !== RECURRENT_CB_PAYMENT_CODE
       */
      if ((isCBCanal || isCBTim) && !isFree) {
        await handleRedirectPayment(purchase, detail);
        return;
      }

      /**
       * * User registered PaymentMeans
       *  - IBAN, CBRecurrent, Mobile Bil
       */
      await handleProcessPayment({
        purchase,
        currentContent,
        turboMedia,
        tracking,
        validationToken,
        purchaseInfo,
      });
    },
    [
      currentContent,
      detail,
      handleProcessPayment,
      handleRedirectPayment,
      isCBCanal,
      isCBTim,
      isFree,
      purchase,
      selectedPaymentMean,
      tracking,
      turboMedia,
    ]
  );
};
